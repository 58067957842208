import React from 'react';

import { Div, Heading, Text } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { Title } from '~components/base/Title';
import { SectionWrapper } from '~components/elements/SectionWapper';

type IStepProps = SpaceProps;

const NumberedStep = styled.div<IStepProps>`
  ${space};
  width: 35px;
  height: 35px;
  display: flex;
  text-align: center;
  border-radius: 50px;
  border: 2px solid #273245;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #273245;
  font-weight: 700;
`;

type Steps = {
  title: string;
  description: string;
};

interface IStepsProps {
  title: string;
  steps: Steps[];
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const StepsSection: React.FC<IStepsProps> = ({ title, steps, icon: Icon }) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper
      hasContainer
      divPaddingTop={['48px', null, null, null, null, null, '52px', '78px', '76px', '100px']}
    >
      <Div px="10px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {Icon && (
          <Div width="60px" height="60px" mb="24px">
            <Icon width="100%" height="100%" />
          </Div>
        )}
        <Title.H2 px="10px" fontWeight="bold" mb={['32px', '64px']}>
          {title}
        </Title.H2>
      </Div>
      <Div
        px="10px"
        display="flex"
        flexWrap={['wrap', null, null, null, null, null, null, 'nowrap']}
        justifyContent="space-between"
      >
        {steps.map((step, index) => (
          <Div
            key={`step${index}`}
            width={['100%', null, null, null, null, '50%', null, 300]}
            mb={['16px', null, null, null, null, null, null, 0]}
          >
            <Div
              mb={6}
              display="flex"
              flexDirection={['column', null, null, null, null, null, null, 'row']}
              alignItems="center"
            >
              <NumberedStep mr={[0, null, null, null, null, null, null, '16px']}>{index + 1}</NumberedStep>
              <Heading.h5 mt={['16px', null, null, null, null, null, null, 0]} mb={0}>
                {t(step.title)}
              </Heading.h5>
            </Div>
            <Div display="flex" justifyContent={['center', null, null, null, null, null, null, 'flex-start']}>
              <Text.p
                width={[0.9, 1, null, null, 0.75, 0.95, 0.85, 1]}
                textAlign={['center', null, null, null, null, null, null, 'left']}
              >
                {t(step.description)}
              </Text.p>
            </Div>
          </Div>
        ))}
      </Div>
    </SectionWrapper>
  );
};
