import {
  AnnexFlat,
  AtticOrBasementFlat,
  BasementFlat,
  BathroomFlat,
  CompleteRenovationFlat,
  FacadeFlat,
  GarageFlat,
  GroundworkFlat,
  KitchenFlat,
  NewConstructionFlat,
  OptionsFlat,
  PartialRenovationFlat,
  RoofFlat,
} from '@schibsted-smb/fireball-icons-prosjektpluss';

export interface WorkTypes {
  name: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  types?: string[];
  /** Tag in CMS to find related posts */
  tag?: string;
  promote: boolean;
  showInForm: boolean;
  showInFormInitially: boolean;
}

export const consumerWorktypes: WorkTypes[] = [
  {
    name: 'Totalrenovering',
    icon: CompleteRenovationFlat,
    types: ['2020'],
    tag: 'totalrenovering',
    promote: true,
    showInForm: true,
    showInFormInitially: true,
  },

  {
    name: 'Delrenovering',
    icon: PartialRenovationFlat,
    types: ['2005'],
    // Yes, it's the same tag as 'helrenovering' on purpose :)
    tag: 'totalrenovering',
    promote: true,
    showInForm: true,
    showInFormInitially: false,
  },

  {
    name: 'Nybygg',
    icon: NewConstructionFlat,
    types: ['2013'],
    tag: 'nybygg',
    promote: true,
    showInForm: true,
    showInFormInitially: false,
  },

  {
    name: 'Påbygg/Tilbygg',
    icon: AnnexFlat,
    types: ['2019'],
    tag: 'påbygg/tilbygg',
    promote: true,
    showInForm: true,
    showInFormInitially: false,
  },

  {
    name: 'Loft eller kjeller',
    icon: AtticOrBasementFlat,
    types: ['2024', '2011'],
    tag: 'loft',
    promote: true,
    showInForm: true,
    showInFormInitially: false,
  },

  {
    name: 'Pusse opp bad',
    icon: BathroomFlat,
    types: ['2001'],
    tag: 'baderom',
    promote: true,
    showInForm: true,
    showInFormInitially: true,
  },

  {
    name: 'Pusse opp kjeller',
    icon: BasementFlat,
    types: ['2009'],
    tag: 'kjeller',
    promote: false,
    showInForm: true,
    showInFormInitially: false,
  },

  {
    name: 'Bygge garasje',
    icon: GarageFlat,
    types: ['2022'],
    tag: 'garasje',
    promote: true,
    showInForm: true,
    showInFormInitially: false,
  },

  {
    name: 'Pusse opp kjøkken',
    icon: KitchenFlat,
    types: ['2010'],
    tag: 'kjøkken',
    promote: false,
    showInForm: true,
    showInFormInitially: false,
  },

  {
    name: 'Fasade',
    icon: FacadeFlat,
    types: ['2006'],
    tag: 'fasade',
    promote: false,
    showInForm: true,
    showInFormInitially: true,
  },

  {
    name: 'Legg eller fornye tak',
    icon: RoofFlat,
    types: ['2018'],
    tag: 'tak',
    promote: false,
    showInForm: true,
    showInFormInitially: true,
  },

  {
    name: 'Grunnarbeid',
    icon: GroundworkFlat,
    types: ['2008'],
    tag: 'grunnarbeid',
    promote: false,
    showInForm: true,
    showInFormInitially: false,
  },

  {
    name: 'Se alle',
    icon: OptionsFlat,
    promote: true,
    showInForm: false,
    showInFormInitially: false,
  },
];
