
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React, { ReactElement, ReactNode } from 'react';

import { HandshakeFlat } from '@schibsted-smb/fireball-icons-prosjektpluss';
import { GetStaticProps } from 'next';
import useTranslation from 'next-translate/useTranslation';

import { PageLayout as Layout } from '~components/layouts/PageWrapper';
import { QualitySection } from '~components/pages/common/QualitySection';
import { StepsSection } from '~components/pages/common/StepsSection';
import LightbulbTrans from '~components/pages/common/StepsSection/lightbulbTrans.svg';
import { VideoSection } from '~components/pages/common/VideoSection';
import { XlDialog } from '~components/pages/common/XlDialog';
import { FaqSection } from '~components/pages/frontPage/FaqSection';
import { HeroSection } from '~components/pages/frontPage/HeroSection';
import { PartnersSection } from '~components/pages/frontPage/PartnersSection';
import { Testimonials } from '~components/pages/frontPage/Testimonials';
import { testimonials as testimonialsData, Testimonial } from '~components/pages/frontPage/Testimonials/data';
import { WorkTypesBanner as WorkType } from '~components/pages/frontPage/WorkTypesBanner';
import { SEO } from '~components/pages/Seo';
import { NextPageWithLayout } from '~types/NextPageWithLayout';

const stepsContent = [
  {
    title: 'frontpage:stepsSection.1.title',
    description: 'frontpage:stepsSection.1.text',
  },
  {
    title: 'frontpage:stepsSection.2.title',
    description: 'frontpage:stepsSection.2.text',
  },
  {
    title: 'frontpage:stepsSection.3.title',
    description: 'frontpage:stepsSection.3.text',
  },
  {
    title: 'frontpage:stepsSection.4.title',
    description: 'frontpage:stepsSection.4.text',
  },
];

interface HomeProps {
  testimonials: Testimonial[];
}

// eslint-disable-next-line @typescript-eslint/require-await
 const _getStaticProps: GetStaticProps<HomeProps> = async () => {
  const shuffledTestimonials = testimonialsData.sort(() => 0.5 - Math.random());
  const testimonialsSelection = shuffledTestimonials.slice(0, 12);

  return {
    props: {
      testimonials: testimonialsSelection,
    },
    revalidate: 60,
  };
};

const Home: NextPageWithLayout<HomeProps> = ({ testimonials }) => {
  const { t } = useTranslation();
  return (
    <>
      <SEO
        shouldIndex
        description="PROSJEKT+ kvalitetssikrer og formidler håndverksbedrifter til bygge- og oppussingsprosjekter. Registrer jobben, og få oppfølging fra start til slutt."
      />
      <XlDialog />
      <HeroSection />
      <StepsSection
        title={t('frontpage:stepsSection.title')}
        steps={stepsContent}
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        icon={LightbulbTrans}
      />
      <QualitySection hasList />
      <VideoSection embedId="NvluTfg7-as" title="Se hvordan PROSJEKT+ fungerer" subtitle="Hvordan fungerer det?" />
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <PartnersSection hasHeader title="Samarbeidspartnere" icon={HandshakeFlat} />
      <FaqSection />
      <Testimonials title="frontpage:testimonials.title" data={testimonials} />
      <WorkType />
    </>
  );
};

Home.getLayout = (page: ReactElement): ReactNode => <Layout>{page}</Layout>;

export default Home;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  